import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { FormHelperText } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme, makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Grid from '@mui/material/Grid';

import Confirm from '../confirm';
import Loader from '../loader';
import util from '../../util';
import { deleteRecord, saveRecord } from '../crud-helper';
import { UserContext } from '../../contexts/userContext';

const useStyles = makeStyles(theme => ({
    select: {
        backgroundColor: theme.palette.primary.light_2,
        color: theme.palette.primary.text.dark,
        "& li": {
            fontWeight: "300",
            fontSize: "14px",
            borderBottom: `1px solid ${theme.palette.primary.text.medium}`
        }
    }
}));

class FieldMappers {
    "boolean" = ({ column, field, fieldLabel, formik, otherProps, palette, classes }) => {
        return <div key={field}>
            <FormControlLabel
                sx={{
                    marginBottom: 1
                }}
                control={
                    <Checkbox
                        sx={{
                            color: palette.primary.text.dark
                        }}
                        {...otherProps}
                        name={field}
                        readOnly={column.readOnly === true}
                        checked={formik.values[field]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                }
                label={fieldLabel}
            />
            <FormHelperText sx={{ color: palette.primary.text.dark }}>{formik.touched[field] && formik.errors[field]}</FormHelperText>
        </div>
    };
    "select" = ({ column, field, fieldLabel, formik, activeRecord, otherProps, palette, classes }) => {
        const options = typeof column.lookup === 'string' ? activeRecord?.lookups[column.lookup] : column.lookup;
        let inputValue = formik.values[field];
        if (column.multiSelect) {
            if (!inputValue || inputValue.length === 0) {
                inputValue = [];
            }
            else {
                if (!Array.isArray(inputValue)) {
                    inputValue = inputValue.split(',').map((e) => parseInt(e));
                }
            }
        }
        return (
            <FormControl
                sx={{
                    color: palette.primary.text.dark, marginBottom: 1
                }}
                fullWidth
                key={field}
                variant="standard">
                <InputLabel sx={{
                    color: palette.primary.text.dark
                }}>{fieldLabel}</InputLabel>
                <Select
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                        borderBottom: `1px solid ${palette.primary.text.medium}`,
                        mt: 0.5,
                        color: palette.primary.text.dark,
                        "& .MuiSvgIcon-root": {
                            color: palette.primary.text.dark,
                        },
                        '& label.Mui-focused': {
                            color: palette.primary.text.dark,
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'yellow',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: palette.primary.text.dark,
                            },
                            '&:hover fieldset': {
                                borderColor: palette.primary.text.dark,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'yellow',
                            }
                        },

                    }}
                    {...otherProps}
                    name={field}
                    multiple={column.multiSelect === true}
                    readOnly={column.readOnly === true}
                    value={inputValue}
                    label={fieldLabel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    MenuProps={{
                        classes: {
                            list: classes.select
                        }
                    }}
                >
                    {Array.isArray(options) && options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                </Select>
                <FormHelperText sx={{ color: palette.primary.text.dark }}>{formik.touched[field] && formik.errors[field]}</FormHelperText>
            </FormControl>
        )
    };
    "string" = ({ column, field, fieldLabel, formik, otherProps, classes }) => {
        const { palette } = useTheme();
        return <TextField
            type="text"
            variant="standard"
            InputProps={{
                readOnly: column.readOnly === true,
            }}
            key={field}
            fullWidth
            name={field}
            label={fieldLabel}
            value={formik.values[field]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched[field] && formik.errors[field]}
            {...otherProps}
            sx={{
                color: palette.primary.text.dark,
                borderBottom: palette.primary.text.dark,
                marginBottom: 1,
                "& input": {
                    color: palette.primary.text.dark,
                    borderBottom: `1px solid ${palette.primary.text.dark}`
                },
                "& input:hover": {
                    borderBottom: `1px solid ${palette.primary.text.dark}`
                },
                "& .MuiInputLabel-formControl ": {

                },
                "&$focused": {
                    color: "tomato",
                    fontWeight: "bold"
                },
                "& label": {
                    color: palette.primary.text.dark
                },
                '&::placeholder': {
                    color: palette.primary.text.dark
                },
                '& .MuiFormHelperText-root': {
                    color: palette.primary.text.dark
                }
            }}
        />
    };
    "number" = ({ column, field, fieldLabel, formik, otherProps, palette, classes }) => {
        const { minValue: min, maxValue: max } = column;
        const finalOtherProps = {
            InputProps: {
                inputProps: { min, max, readOnly: column.readOnly === true }
            },
            type: 'number',
            ...otherProps
        }
        return this.string({ column, field, fieldLabel, formik, otherProps: finalOtherProps });
    };
    "password" = ({ column, field, fieldLabel, formik, otherProps, theme, classes }) => {
        const finalOtherProps = {
            type: 'password',
            ...otherProps
        }
        return this.string({ column, field, fieldLabel, formik, otherProps: finalOtherProps });
    };
    "date" = ({ column, field, fieldLabel, formik, otherProps, palette, classes }) => {
        return <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                {...otherProps}
                label={fieldLabel}
                variant="standard"
                readOnly={column.readOnly === true}
                key={field}
                fullWidth
                name={field}
                value={formik.values[field]}
                onChange={(value) => formik.setFieldValue(field, value)}
                onBlur={formik.handleBlur}
                helperText={formik.touched[field] && formik.errors[field]}
                PopperProps={{
                    sx: {
                        color: palette.primary.text.dark,
                        borderBottom: palette.primary.text.dark,
                        marginBottom: 1,
                        "& input": {
                            color: palette.primary.text.dark,
                            borderBottom: `1px solid ${palette.primary.text.dark}`
                        },
                        "& input:hover": {
                            borderBottom: `1px solid ${palette.primary.text.dark}`
                        },
                        "&$focused": {
                            fontWeight: "bold"
                        },
                        "& .label": {
                            color: palette.primary.text.dark,
                        },
                        '&::placeholder': {
                            color: palette.primary.text.dark,
                        },
                        "& .MuiPaper-root": {
                            backgroundColor: palette.primary.light_3,
                            color: palette.primary.text.dark,
                            "& .MuiSvgIcon-root": {
                                color: palette.primary.text.dark
                            },
                            "& .MuiTypography-root": {
                                color: palette.primary.text.dark
                            },
                            "& .MuiTabs-indicator": {
                                backgroundColor: palette.primary.text.dark
                            },
                            "& .css-1umqo6f": {
                                color: palette.primary.text.dark
                            }
                        }
                    }
                }}

                renderInput={(params) => {
                    const props = { ...params, variant: "standard" };
                    return <TextField
                        {...props}
                        helperText={formik.errors[field]}
                        fullWidth
                        sx={{
                            color: palette.primary.text.dark,
                            borderBottom: palette.primary.text.dark,
                            marginBottom: 1,
                            "& input": {
                                color: palette.primary.text.dark,
                                borderBottom: `1px solid ${palette.primary.text.dark}`
                            },
                            "& input:hover": {
                                borderBottom: `1px solid ${palette.primary.text.dark}`
                            },
                            "&$focused": {
                                fontWeight: "bold"
                            },
                            "& label": {
                                color: palette.primary.text.dark,
                            },
                            '&::placeholder': {
                                color: palette.primary.text.dark,
                            },
                            "& .MuiInput-root": {
                                "& .MuiInputAdornment-root": {
                                    "& .MuiButtonBase-root": {
                                        color: `${palette.primary.text.dark}!important`
                                    }
                                }
                            }
                        }}
                    />
                }}
            />
        </LocalizationProvider>
    };
    "time" = ({ column, field, fieldLabel, formik, otherProps, palette, classes }) => {
        let inputValue = formik.values[field];
        if (column.isUtc) {
            inputValue = dayjs.utc(inputValue).utcOffset(dayjs().utcOffset(), true).format();
        }
        return <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                {...otherProps}
                label={fieldLabel}
                variant="standard"
                readOnly={column.readOnly === true}
                key={field}
                fullWidth
                name={field}
                value={inputValue}
                onChange={(value) => {
                    if (column.isUtc) {
                        value = (value && value.isValid()) ? value.format('YYYY-MM-DDTHH:mm:ss') + '.000Z' : null;
                    }
                    return formik.setFieldValue(field, value);
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched[field] && formik.errors[field]}
                PopperProps={{
                    sx: {
                        color: palette.primary.text.dark,
                        "& .MuiPaper-root": {
                            backgroundColor: palette.primary.light_3,
                            color: palette.primary.text.dark,
                            "& .MuiSvgIcon-root": {
                                color: palette.primary.text.dark
                            },
                            "& .MuiTypography-root": {
                                color: palette.primary.text.dark
                            },
                            "& .MuiTabs-indicator": {
                                backgroundColor: palette.primary.text.dark
                            },
                            "& .css-1umqo6f": {
                                color: palette.primary.text.dark
                            }
                        }
                    }
                }}
                sx={{
                    color: palette.primary.text.dark,
                }}
                renderInput={(params) => {
                    const props = { ...params, variant: "standard" };
                    return <TextField
                        sx={{
                            color: palette.primary.text.dark,
                            borderBottom: palette.primary.text.dark,
                            marginBottom: 1,
                            "& input": {
                                color: palette.primary.text.dark,
                                borderBottom: `1px solid ${palette.primary.text.dark}`
                            },
                            "& input:hover": {
                                borderBottom: `1px solid ${palette.primary.text.dark}`
                            },
                            "&$focused": {
                                color: "tomato",
                                fontWeight: "bold"
                            },
                            "& label": {
                                color: palette.primary.text.dark,
                            },
                            '&::placeholder': {
                                color: palette.primary.text.dark,
                            },
                            "& .MuiInput-root": {
                                "& .MuiInputAdornment-root": {
                                    "& .MuiButtonBase-root": {
                                        color: `${palette.primary.text.dark}!important`
                                    }
                                }
                            }
                        }}
                        {...props}
                        helperText={formik.errors[field]}
                        fullWidth />
                }}
            />
        </LocalizationProvider>
    };
}

const fieldMappers = new FieldMappers();


export default function FormDialog({ modelConfig, activeRecord, setActiveRecord, parent }) {
    const { title } = modelConfig;
    const [showConfirm, setShowConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userContext, setUserContext] = useContext(UserContext);
    const { role } = userContext || {};
    const { edit, delete: canDelete } = util.hasPermission({ role, module: modelConfig.module });

    const validationSchema = () => {
        let validationConfig = {};
        for (const column of modelConfig.columns) {
            const { field, label, header, type = 'string', requiredIfNew = false, required = false } = column;
            const formLabel = label || header;
            if (!formLabel) {
                continue;
            }
            let config;
            if (required) {
                if (type === 'string') {
                    config = yup.string().label(formLabel);
                    config = yup.string().nullable(true);
                    config = config.required(`${formLabel} is required`);
                }
                if (type === 'boolean') {
                    config = yup.bool().label(formLabel);
                    config = yup.bool().oneOf([true], `${formLabel} is required`);
                    config = config.required();
                }
            }
            if (requiredIfNew) {
                if (activeRecord?.id != null && activeRecord?.id !== '') {
                    if (type === 'password') {
                        config = yup.string().label(formLabel);
                        config = config.notRequired();
                    }
                }
            }
            if (config) {
                validationConfig[field] = config;
            }
        }
        return yup.object({ ...modelConfig.validationSchema, ...validationConfig });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...modelConfig.initialValues, ...activeRecord?.record },
        validationSchema: validationSchema,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            setIsLoading(true);
            saveRecord({ api: modelConfig.api, id: activeRecord?.id, values })
                .then(success => {
                    if (success) {
                        if (modelConfig.api === "api/notifications") {
                            const user = Object.assign({}, userContext);
                            if (user.alerts > 0 && activeRecord.record.acknowledged === false && activeRecord.record.requiresAcknowledgment === true){
                                user.alerts--;
                            }
                            setUserContext(user);
                        }
                        setActiveRecord(undefined);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    });

    const handleDelete = () => {
        setShowConfirm(true);
    };

    const handleClose = () => {
        setActiveRecord(undefined, { refresh: false });
    };

    const handleDeleteCancel = () => {
        setShowConfirm(false);
    };

    const handleDeleteConfirm = () => {
        setShowConfirm(false);
        setIsLoading(true);
        deleteRecord({ id: activeRecord.id, api: modelConfig.api, }).then((success) => {
            if (success) {
                setActiveRecord(undefined);
            }
        }).finally(() => setIsLoading(false));
    }

    let autoFocusSet = false;
    const { palette } = useTheme()
    const classes = useStyles();



    return (
        <div>
            <Loader isLoading={isLoading} />
            <Confirm title={"Delete"} body={"Are you sure you want to delete?"} open={showConfirm} onCancel={handleDeleteCancel} onConfirm={handleDeleteConfirm} />
            <Dialog open={activeRecord !== undefined} onClose={handleClose}>
                <DialogTitle
                    sx={{
                        backgroundColor: palette.primary.light_1,
                        color: palette.primary.text.dark
                    }}>{title}: {activeRecord?.title}</DialogTitle>
                <DialogContent
                    sx={{
                        backgroundColor: palette.primary.light_1,
                        color: palette.primary.text.dark
                    }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            {modelConfig.formDef.map((column, index) => {
                                const { hideConfig } = column;
                                if (hideConfig) {
                                    const { values } = formik;
                                    const { onHide } = hideConfig;
                                    const { isHide, column: updatedColumn } = onHide({ activeRecord, formValues: values, column, userContext });
                                    if (updatedColumn) {
                                        column = updatedColumn;
                                    }
                                    if (isHide) {
                                        return null;
                                    }
                                }
                                if (parent && column.lookup && parent === column.lookup) {
                                    return null;
                                }
                                const field = column.field;
                                const fieldLabel = column.label || column.header;
                                const otherProps = {};
                                if (!autoFocusSet && column.readOnly !== true) {
                                    otherProps.autoFocus = true;
                                    autoFocusSet = true;
                                }
                                let fieldType = column.type;
                                if (!column.type) {
                                    fieldType = column.lookup ? 'select' : 'string';
                                }
                                return (
                                    column.layout === 'column' ? <Grid item lg={6}>
                                        {fieldMappers[fieldType]({ modelConfig, column, field, fieldLabel, formik, activeRecord, otherProps, palette, classes })}
                                    </Grid> : <Grid item lg={12}> {fieldMappers[fieldType]({ modelConfig, column, field, fieldLabel, formik, activeRecord, otherProps, palette, classes })}</Grid>
                                );
                            })}
                        </Grid>

                        <DialogActions>
                            {canDelete && <Button variant="contained" onClick={handleDelete} disabled={activeRecord?.id === null} color="error" >Delete</Button>}
                            <div style={{ flex: 1 }} />
                            <Button variant="contained" onClick={handleClose} color="secondary">Cancel</Button>
                            {<Button disabled={activeRecord?.id !== null && !edit} variant="contained" type="submit" color="success">Save</Button>}
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div >
    );
}
