import { UserContext } from './contexts/userContext';
export const theme = (mode) => {
	const subDomain = window.location.hostname.split('.')[0];

	let isDips = subDomain.indexOf('reveal') == -1;

	const themeSelect = {
		modeDarkmain: isDips ? '#121734' : '#152F54',
		modeDark: isDips ? '#161D40' : '#1d4574', // changed for reveal
		modeDarklight_1: isDips ? '#222D62' : '#226BB5',
		modeDarklight_4: isDips ? '#22BCFC' : '#199CFF',
		modeDarkheader_bg: isDips ? '#222D62' : '#226BB5',
		modeDarkprint_btn: isDips
			? 'transparent linear-gradient(180deg, #19E891 0%, #26620B 100%) 0% 0% no-repeat padding-box'
			: '#199CFF',
		modeLightlight_4: isDips ? '#d3d3d3' : '#199cff',
		modeLightheader_bg: isDips ? 'rgb(25, 118, 210)' : '#226BB5',
		modeLightprint_btn: isDips
			? 'transparent linear-gradient(180deg, rgb(25, 118, 210) 0%, rgba(25, 118, 210,0.8) 100%) 0% 0% no-repeat padding-box'
			: '#226BB5',
		modeDarkclear_btn: isDips ? '#00E965' : '#FFFFFF',
		modeDarkmedium: isDips ? '#1B224C' : '#152F54',
		modeDarklight_2: isDips ? '#343D77' : '#226BB5',
		modeDarklight_3: isDips ? '#474F8B' : '#1D4574',
		modeLightlight_7: isDips ? '#fff' : '#226BB5',
		modeLightPrimaryTextMedium: isDips ? '#575757' : '#ffffffd5',
		darkModeGraphlines: isDips ? '#cfcfcf' : '#cfcfcf',
		lightModeGraphlines: isDips ? '#226BB5' : '#226BB5',
		darkModeEvenBackground: isDips ? '#222D62' : '#babdbf',
		selected_tab_color:isDips? '#226BB5':'#222D62'
	};

	return {
		appDrawer: {
			width: 320
		},
		palette: {
			mode,
			...(mode === 'dark'
				? {
						primary: {
							main: themeSelect.modeDarkmain,
							dark: themeSelect.modeDark,
							light: '#1D2551',
							medium: themeSelect.modeDarkmedium,
							light_1: themeSelect.modeDarklight_1,
							light_2: themeSelect.modeDarklight_2,
							light_3: themeSelect.modeDarklight_3,
							light_4: themeSelect.modeDarklight_4,
							light_5: '#626BAE', // done
							light_6: '#474F8B',
							light_7: themeSelect.modeDarkmain,
							selected_tab: themeSelect.selected_tab_color,
							unselected_tab:'#FFFFFF',
							text: {
								dark: '#fff',
								medium: '#ffffffd5',
								medium2: '#ffffffd5',
								light: '#ffffff33'
							},
							gridLines: themeSelect.darkModeGraphlines,
							gridBackground: themeSelect.darkModeEvenBackground
						},
						colors: {
							header_bg: themeSelect.modeDarkheader_bg,
							charts: ['#5969c5', '#707ecd', '#4153bd', '#8893d6', '#cfd4ee', '#a0a9de', '#b7bee6'],
							clear_btn: themeSelect.modeDarkclear_btn,
							print_btn: themeSelect.modeDarkprint_btn,
							icons: '#00DC00',
							cancel_icon: '#FF2C2C'
						}
				  }
				: {
						primary: {
							main: '#fff',
							dark: '#babdbf', // done change
							light: '#fff', // done
							medium: '#00000009', // done
							light_1: '#fff', // done
							light_2: '#fff', // done
							light_3: '#ebebeb', // done
							light_4: themeSelect.modeLightlight_4, // done
							light_5: '#292929', // done
							light_6: '#9E9E9E',
							light_7: themeSelect.modeLightlight_7,
							selected_tab: themeSelect.selected_tab_color,
							unselected_tab:'#000000',
							text: {
								dark: '#000',
								medium: themeSelect.modeLightPrimaryTextMedium,
								medium2: '#575757',
								light: '#808080'
							},
							gridLines: themeSelect.lightModeGraphlines
						},
						colors: {
							header_bg: themeSelect.modeLightheader_bg,
							charts: ['#5969c5', '#707ecd', '#4153bd', '#8893d6', '#cfd4ee', '#a0a9de', '#b7bee6'],
							clear_btn: '#000',
							print_btn: themeSelect.modeLightprint_btn,
							icons: '#000'
						}
				  }),
			fixed: {
				white: '#fff',
				black: '#000'
			}
		}
	};
};
