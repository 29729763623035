import { React, useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { useTheme, styled } from '@mui/styles';
import { Container, Grid, Paper, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useDataFilter } from '../contexts/dataFiltersContext';
import { UserContext } from '../contexts/userContext';
import CardComponent from '../Components/common/Card';
import PieChart from '../Components/common/PieChart';
import { getList } from '../Components/crud-helper';
import { api as apiDefs } from '../config/authConfig';
import models from '../config/models';
import FormBase from './form-base';
import Filters from '../Components/Filters';
import { sensorProfileIds, dateFormats } from 'dips-models';
import ReportLogo from '../assets/logo.png'
import util from '../util';
const { reportDurationDateFormat, reportCreatedDateFormat } = dateFormats;

const alertModel = models.filter((ele) => ele.title === 'Alert')[0];
const alertModelDashboard = { ...alertModel };

// const { date: dateFormat } = dateFormats;
const dateFormat = 'YYYY-MM-DD'; //TODO: Need to discuss with the Internal team, as elastic support only YYYY-MM-DD format

const PieChartPlaceholder = styled('div')(({ theme }) => ({
	display: 'flex',
	border: `18px solid ${theme.palette.primary.light_2}`,
	borderRadius: '50%',
	margin: 'auto',
	marginBottom: 10,
	width: 120,
	height: 120
}));
function DashboardContent() {
	const [userContext] = useContext(UserContext);
	const isUserLimited = ['User Limited'].includes(userContext.role);
	const [hospitalsFilterData, setHospitalsFilterData] = useState([]);
	const [roomsFilterData, setRoomsFilterData] = useState([]);
	const [sensorFilterData, setSensorFilterData] = useState([]);
	const [dashboardData, setDashboardData] = useState({
		humidity: {
			InRange: 0,
			OutOfRange: 0,
			RequiresCA: 0,
			Total: 0
		},
		temperature: {
			InRange: 0,
			OutOfRange: 0,
			RequiresCA: 0,
			Total: 0
		}
	});

	const [parentFilters, setParentFilters] = useDataFilter({
		Date: {
			operator: 'between',
			startDate: dayjs().subtract(6, 'day').format(dateFormat),
			endDate: dayjs().format(dateFormat)
		}
	});
	const [label, setLabel] = useState({ roomLabel: '', sensorLabel: '', hospitalLabel: '' });

	useEffect(() => {
		let parentF = { ...parentFilters };
		delete parentF.InRange;
		getList({
			page: 0,
			pageSize: 10,
			sortModel: [],
			gridColumns: [],
			api: `${apiDefs.base}/dashboard`,
			setIsLoading: () => { },
			setData: (data) => {
				if (data.rows) {
					setDashboardData(data.rows);
				}
			},
			modelConfig: { formDef: [] },
			parentFilters: {}
		});
	}, [parentFilters]);

	const { palette } = useTheme();

	return (
		<Container
			maxWidth="xl"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '40px',
				mt: 3,
				mb: 4,
				pt: 2,
				backgroundColor: palette.primary.light_1,
				padding: '26px 20px 10px 0px'
			}}
		>

			<Grid>
				<Grid item xs={12}>
					<Filters
						setParentFilters={setParentFilters}
						parentFilters={parentFilters}
						setLabel={setLabel}
						label={label}
						hospitalsFilterData={hospitalsFilterData}
						setHospitalsFilterData={setHospitalsFilterData}
						roomsFilterData={roomsFilterData}
						setRoomsFilterData={setRoomsFilterData}
						sensorFilterData={sensorFilterData}
						setSensorFilterData={setSensorFilterData} />
				</Grid>
				<Box className="only-print-content print-pd0" sx={{ p: 1 }}>
					<Box display="flex" justifyContent="center" alignItems="center" mb={1}>
						<img src={ReportLogo} style={{ width: 200, height: 40 }} alt={`${userContext?.docTitle}`} />
					</Box>
					<Box
						sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
						mb={5}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						Active Alert History Report
					</Box>
					<Box className="report-header-container">
						<Box>Hospital: {util.printHospital(hospitalsFilterData, parentFilters)}</Box>
						<Box>
							Dates: {dayjs(parentFilters.Date.startDate).format(reportDurationDateFormat)} to{' '}
							{dayjs(parentFilters.Date.endDate).format(reportDurationDateFormat)}
						</Box>
						<Box>Created: {dayjs().format(reportCreatedDateFormat)}</Box>
					</Box>
				</Box>
			</Grid>

			<Grid row>
				<Typography
					component="h3"
					color="inherit"
					sx={{
						color: palette.primary.text.dark,
						fontWeight: '500'
					}}
					py={1.5}
				>
					Temperature Graphic
				</Typography>
				<Grid
					container
					columnSpacing={{ xs: 2, sm: 2, md: 3 }}
					rowSpacing={{ xs: 2, sm: 2, md: 3 }}
					className="print-flex"
				>
					{!isUserLimited && (
						<CardComponent
							title="Corrective Action Required"
							value={dashboardData.temperature.RequiresCA}
							redirectTo={'/notifications?requiresComment=true&requiresAcknowledgment=true&profileId=2'}
							// total={dashboardData.temperature.Total}
							progress={'100%'}
						>
							<PieChart
								data={[
									{
										name: 'Corrective Action Required',
										value: 1,
										fill: palette.error.main
									}
								]}
							/>
						</CardComponent>
					)}

					{!isUserLimited && (
						<CardComponent
							title="Out Of Range"
							value={dashboardData.temperature.OutOfRange}
							redirectTo={`/sensor?telemetryProfileId=${sensorProfileIds.temperature}&telemetryInRange=false`}
							// total={dashboardData.temperature.Total}
							progress={'100%'}
						>
							<PieChart
								color={palette.warning.main}
								data={[
									{
										name: 'Out of Range',
										value: 1,
										fill: palette.warning.main
									}
								]}
							/>
						</CardComponent>
					)}

					<CardComponent
						title="Within Range"
						value={dashboardData.temperature.InRange}
						redirectTo={`/sensor?telemetryProfileId=${sensorProfileIds.temperature}&telemetryInRange=true`}
						// total={dashboardData.temperature.Total}
						progress={'100%'}
					>
						<PieChart
							data={[
								{
									name: 'In Range',
									value: 1,
									fill: palette.success.main
								}
							]}
						/>
					</CardComponent>
				</Grid>
			</Grid>

			<Grid row>
				<Typography
					component="h3"
					color="inherit"
					sx={{
						color: palette.primary.text.dark,
						fontWeight: '500'
					}}
					py={1.5}
				>
					Humidity Graphic
				</Typography>
				<Grid
					container
					columnSpacing={{ xs: 2, sm: 2, md: 3 }}
					rowSpacing={{ xs: 2, sm: 2, md: 3 }}
					className="print-flex"
				>
					{!isUserLimited && (
						<CardComponent
							title="Corrective Action Required"
							value={dashboardData.humidity.RequiresCA}
							redirectTo={'/notifications?requiresComment=true&requiresAcknowledgment=true&profileId=43'}
							// total={dashboardData.humidity.Total}
							progress={'100%'}
						>
							<PieChart
								data={[
									{
										name: 'Corrective Action Required',
										value: 1,
										fill: palette.error.main
									}
								]}
							/>
						</CardComponent>
					)}

					{!isUserLimited && (
						<CardComponent
							title="Out Of Range"
							value={dashboardData.humidity.OutOfRange}
							redirectTo={`/sensor?telemetryProfileId=${sensorProfileIds.humidity}&telemetryInRange=false`}
							// total={dashboardData.humidity.Total}
							progress={'100%'}
						>
							<PieChart
								data={[
									{
										name: 'Out of Range',
										value: 1,
										fill: palette.warning.main
									}
								]}
							/>
						</CardComponent>
					)}

					<CardComponent
						title="Within Range"
						value={dashboardData.humidity.InRange}
						redirectTo={`/sensor?telemetryProfileId=${sensorProfileIds.humidity}&telemetryInRange=true`}
						// total={dashboardData.humidity.Total}
						progress={'100%'}
					>
						<PieChart
							data={[
								{ name: 'In Range', value: 1, fill: palette.success.main }
							]}
						/>
					</CardComponent>
				</Grid>
			</Grid>

			{!isUserLimited && (
				<Grid row>
					<Grid spacing={3}>
						<Paper
							sx={{
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<FormBase
								title="Active Alerts"
								modelConfig={alertModelDashboard}
								key={alertModelDashboard.title}
								parentFilters={{ ...parentFilters, isOpen: true }}
								isForDashboard={true}
							/>
						</Paper>
					</Grid>
				</Grid>
			)}
		</Container>
	);
}

export default DashboardContent;