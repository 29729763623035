
function* getTelemetryMessage(messages, otherProps = {}, logger) {
    for (const doc of messages) {
        let { Data, Description } = doc;
        logger.warn(`${Description} Data: ${JSON.stringify(doc)}`);
        if (typeof Data === 'string') {
            if (/true/i.test(Data)) {
                doc.Data = 1;
            } else if (/false/i.test(Data)) {
                doc.Data = 0;
            } else {
                doc.Data = Number(Data);
            }
        }
        doc.Date = doc.Date.substr(0, 19) + "Z";
        yield { ...otherProps, ...doc };
    }
}
const DoorStatus = {
    Open: 2,
    Close: 0
};
const StatusCode = {
    Ok: 200,
    NotFound: 404
}
const AlertTypes = {
    Value: 1,
    Count: 2
};

const getTimeOfDay = function (date) {
    return date.getUTCHours() * 3600 + date.getUTCMinutes() * 60 + date.getUTCSeconds();
}

const baseQuery = {
    size: 1,
    query: {
        bool: {
            must: [
                {
                    term: {
                        sensorId: ""
                    }
                },
                {
                    term: {
                        isOpen: true
                    }
                }
            ],
            must_not: []
        }
    }
}
const deepClone = (objectpassed) => {
    if (objectpassed === null || typeof objectpassed !== 'object') {
        return objectpassed;
    }
    // give temporary-storage the original obj's constructor
    const temporaryStorage = objectpassed.constructor();
    for (const key in objectpassed) {
        temporaryStorage[key] = deepClone(objectpassed[key]);
    }
    return temporaryStorage;
}
class Telemetry {

    dataClient = null;
    lastIndex = null;
    lastDurationIndex = null;
    lastAlertIndex = null;
    lastAlertOORIndex = null;
    logger = null;
    /**
     *
     * @param {*} param0 - start time
     * @param {*} param0 - end time
     * @returns
     */
    calculateTimeDiffSeconds = ({ startTime, endTime }) => {
        return (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000;
    }
    save = async ({ models = this.db?.models, hubId, data, dataClient = this.dataClient, logger = this.logger, receivedAt = new Date() }) => {

        const Sensor = models.sensor;
        const AlertDefinitionSensor = models.alertDefinitionSensor;
        const Case = models.case;
        const Op = this.db?.Sequelize.Op;
        const indexName = `telemetry-${new Date().toISOString().substring(0, 7)}`;
        const durationIndexName = `telemetryduration-${new Date().toISOString().substring(0, 7)}`;
        const alertIndexName = 'alert';
        const alertOutOfRangeIndex = 'alert-oor';
        if (indexName !== this.lastIndex) {
            const telemetryIndexRes = await dataClient.indices.exists({ index: indexName });
            if (telemetryIndexRes.statusCode === StatusCode.NotFound) {
                await dataClient.indices.create({
                    index: indexName
                });
            }
            this.lastIndex = indexName;
        }
        if (durationIndexName !== this.lastDurationIndex) {
            const durationIndexRes = await dataClient.indices.exists({ index: durationIndexName });
            if (durationIndexRes.statusCode === StatusCode.NotFound) {
                await dataClient.indices.create({
                    index: durationIndexName
                });
            }
            this.lastDurationIndex = durationIndexName;
        }
        if (alertIndexName !== this.lastAlertIndex) {
            const alertIndexRes = await dataClient.indices.exists({ index: alertIndexName });
            if (alertIndexRes.statusCode === StatusCode.NotFound) {
                await dataClient.indices.create({
                    index: alertIndexName
                });
            }
            this.lastAlertIndex = alertIndexName;
        }
        if (alertOutOfRangeIndex !== this.lastAlertOORIndex) {
            const alertOORIndexRes = await dataClient.indices.exists({ index: alertOutOfRangeIndex });
            if (alertOORIndexRes.statusCode === StatusCode.NotFound) {
                await dataClient.indices.create({
                    index: alertOutOfRangeIndex
                });
            }
            this.lastAlertOORIndex = alertOutOfRangeIndex;
        }
        const roomInclude = [
            { model: models.room, as: 'room', attributes: ['hospitalId'] },
            { model: models.sensorType, as: 'sensorType', attributes: ['isDuration'] }
        ];
        const alertDefinitionInclude = [
            { model: models.alertDefinition, as: 'alertDefinition', attributes: ['id', 'min', 'max', 'days', 'inCase', 'requiresAcknowledgment', 'requiresComment', 'startTime', 'endTime', 'to', 'alertTypeId'], required: true }
        ]
        const otherProps = { hubId, receivedAt };
        const records = [...getTelemetryMessage(data, otherProps, logger)];
        const sensorIds = [];
        for (const { SensorId: sensorId } of records) {
            if (sensorId) {
                sensorIds.push(sensorId.toString());
            }
        }
        if (sensorIds.length) {
            const sensors = await Sensor.findAll({ where: { code: sensorIds }, include: roomInclude });
            const latestData = {};
            for (const sensor of sensors) {
                latestData[sensor.id] = { Date: new Date(sensor.telemetryDate), Data: Number(sensor.telemetryData), State: sensor.telemetryState };
            }

            for (let i = 0, len = records.length; i < len; i++) {
                let record = records[i];
                if (record.SensorId) {
                    const sensor = sensors.find(s => s.code === record.SensorId.toString());
                    if (sensor) {
                        record.sensorId = sensor.id;
                        record.roomId = sensor.roomId;
                        record.hospitalId = sensor.room.hospitalId;
                        const readingDate = new Date(record.Date);
                        record.Date = new Date(record.Date);
                        const isDuration = sensor.sensorType.isDuration;
                        const latestRecord = latestData[sensor.id];
                        const isDoorOpen = record.State === DoorStatus.Open;
                        const alertDefinitions = await AlertDefinitionSensor.findAll({ where: { sensorId: record.sensorId }, include: alertDefinitionInclude });
                        const cases = await Case.findAll({
                            where: {
                                roomId: record.roomId,
                                startDateTime: { [Op.lte]: record.Date },
                                endDateTime: { [Op.gt]: record.Date }
                            }
                        });
                        if (cases?.length) {
                            record.caseId = cases[0].id;
                        }

                        logger.warn(`isDuration: ${isDuration}, sensorId=${record.sensorId}, SensorId: ${record.SensorId}, State: ${record.State}`);
                        // if door and we have a new value that's different than previous value
                        if (!latestRecord || (latestRecord.Date <= readingDate && (latestRecord.Data !== record.Data || isDuration))) {
                            let alertData = record, openDurationCount = 0;
                            if (isDuration) {
                                alertData = await this.sensorOpenclose({ record: record, isOpen: isDoorOpen, index: durationIndexName, roomId: sensor.roomId, isDuration });
                                if (alertData && record?.caseId) {
                                    //calculate counts of records for that particular sensor and date(day). And this count will be used for alerts.
                                    openDurationCount = await this.getOpenCounts({ record, index: durationIndexName });
                                }
                            }
                            if (alertData) {
                                const valueForAlert = isDuration ? alertData.duration : alertData.Data;
                                const isOpenOutOfRange = (valueForAlert < sensor.minThreshold || valueForAlert > sensor.maxThreshold);
                                const shouldClose = isDuration && !isDoorOpen;
                                if (shouldClose) {
                                    //in case of duration alert, alertData.Date is initial value of door open. When alert needs to close, then we need to add EndTime which should be taken from record.Date
                                    alertData.Date = record.Date;
                                }
                                await this.sensorOpenclose({ record: alertData, isOpen: isOpenOutOfRange, index: alertOutOfRangeIndex, roomId: sensor.roomId, shouldClose });
                                if (alertDefinitions && alertDefinitions.length) {
                                    for (let ind = 0, length = alertDefinitions.length; ind < length; ind++) {
                                        const alertDefinition = alertDefinitions[ind];
                                        const { min, max, to, days, startTime, endTime, alertTypeId } = alertDefinition.alertDefinition;
                                        let isOpen = (valueForAlert < min || valueForAlert > max);
                                        if (isDuration && alertTypeId === AlertTypes.Count) {
                                            isOpen = record.caseId ? openDurationCount > max : false;
                                            if (isOpen) {
                                                const createdAlreadyCount = await this.getOpenCounts({ record, index: alertIndexName, alertDefinition });
                                                isOpen = createdAlreadyCount === 0;
                                            }
                                        }

                                        let alertRecipientData = await this.sensorOpenclose({ record: alertData, isOpen, index: alertIndexName, roomId: sensor.roomId, shouldClose, alertDefinition });
                                        if (alertRecipientData && alertRecipientData.alertId) {
                                            const recipients = (to?.length > 0 ? to.split(',') : []);
                                            const alertDateTime = new Date(alertRecipientData.Date);
                                            const alertSeconds = getTimeOfDay(alertDateTime);

                                            const alertDay = alertDateTime.getDay();
                                            const recipientDays = days.split(",").map((e) => parseInt(e));

                                            const startDateTime = new Date(startTime);
                                            const endDateTime = new Date(endTime);

                                            let tries = 5;
                                            if (recipientDays.indexOf(alertDay) > -1 && alertSeconds >= getTimeOfDay(startDateTime) && alertSeconds <= getTimeOfDay(endDateTime)) {
                                                tries = 0;
                                            }
                                            logger.warn(`Setting tries - ${tries} for alertDay: ${alertDay}, recipientDays=${JSON.stringify(recipientDays)}, alertSeconds=${alertSeconds}, startDateTime(seconds)=${getTimeOfDay(startDateTime)},  endDateTime(seconds)=${getTimeOfDay(endDateTime)}, startDateTime=${startDateTime}, endDateTime=${endDateTime}, recipients=${JSON.stringify(recipients)}`);
                                            for (let recIndex = 0, total = recipients.length; recIndex < total; recIndex++) {
                                                const alertRecipient = {};
                                                alertRecipient.alertId = alertRecipientData.alertId;
                                                alertRecipient.tries = tries;
                                                alertRecipient.recipientEmail = recipients[recIndex].trim();
                                                alertRecipient.sentOn = null;
                                                alertRecipient.escalationLevel = 0;
                                                await this.saveAlertRecipient({ data: alertRecipient });
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (!latestData[sensor.id] || latestData[sensor.id].Date < readingDate) {
                            latestData[sensor.id] = record;
                        }
                    }
                    else {
                        logger.warn(`No record in DB for sensorId: ${record.SensorId}`);
                    }
                }
            }
            for (const sensorId in latestData) {
                const sensor = sensors.find(s => s.id === sensorId);
                const record = latestData[sensorId];
                if (sensor.telemetryDate && sensor.telemetryDate >= new Date(record.Date)) {
                    continue;
                }
                await Sensor.update(Object.assign({}, record, {
                    telemetryProfileId: record.ProfileId,
                    telemetryState: record.State,
                    telemetryInRange: record.InRange,
                    telemetrySignalStrength: record.SignalStrength,
                    telemetryData: record.Data,
                    telemetryDescription: record.Description,
                    telemetryVoltage: record.Voltage,
                    telemetryReceivedAt: receivedAt,
                    telemetryDate: record.Date
                }), { where: { id: sensor.id } });
            }
        }
        await dataClient.helpers.bulk({
            datasource: records,
            onDocument(doc) {
                return {
                    index: { _index: indexName }
                }
            }
        });
    }
    getOpenCounts = async ({ record, index, alertDefinition }) => {
        const elasticQuery = deepClone(baseQuery);
        elasticQuery.query.bool.must[0].term.sensorId = record.sensorId;
        elasticQuery.query.bool.must[1] = { term: { caseId: record.caseId } };
        if (alertDefinition) {
            elasticQuery.query.bool.must.push({ term: { alertDefinitionId: alertDefinition.alertDefinition.id } });
            elasticQuery.query.bool.must.push({ term: { alertDefinitionSensorId: alertDefinition.id } });
        }
        elasticQuery.size = 0;
        elasticQuery.track_total_hits = true;
        const elasticResponse = await this.dataClient.search({
            method: 'POST',
            index: index,
            body: elasticQuery,
            filter_path: 'hits.total'
        });

        const { body } = elasticResponse;
        return body?.hits?.total?.value;
    }
    sensorOpenclose = async ({ record, isOpen, shouldClose, index, roomId, alertDefinition, isDuration }) => {
        const elasticQuery = deepClone(baseQuery);
        elasticQuery.query.bool.must[0].term.sensorId = record.sensorId;
        if (alertDefinition) {
            elasticQuery.query.bool.must.push({ term: { alertDefinitionId: alertDefinition.alertDefinition.id } });
            elasticQuery.query.bool.must.push({ term: { alertDefinitionSensorId: alertDefinition.id } });
        }
        const logger = this.logger;
        const elasticResponse = await this.dataClient.search({
            method: 'POST',
            index: index,
            body: elasticQuery,
            filter_path: 'hits.hits._id,hits.hits._source'
        });
        const { body } = elasticResponse;
        let endTime = record.Date, startTime = record.Date;
        let duration = record.duration || 0;
        const hits = body?.hits?.hits || [];
        const recordInDb = hits[0];
        if (recordInDb) {
            startTime = recordInDb._source.Date;
            if (!duration) {
                duration = this.calculateTimeDiffSeconds({ startTime: startTime, endTime: record.Date });
            }
        }
        const dataToUpdate = {};

        if (!isOpen) {// record needs to close
            if (recordInDb) { // a record is opened already which needs to close now
                dataToUpdate.duration = duration;
                dataToUpdate.EndTime = record.Date;
                dataToUpdate.isOpen = false;
            } else {
                logger.warn(`No record is opened for sensor Id = ${record.SensorId}, index=${index}`);
            }
        }
        if (isOpen) {// record needs to open
            if (recordInDb) { // an record is opened already then increase duration only.
                dataToUpdate.duration = duration;
            }
            else { // new record to be open
                Object.assign(dataToUpdate, record);
                if (!dataToUpdate.duration) {
                    dataToUpdate.duration = 0;
                }
                if (alertDefinition) {
                    const { id, min, max } = alertDefinition.alertDefinition;
                    dataToUpdate.alertDefinitionId = id;
                    dataToUpdate.alertDefinitionSensorId = alertDefinition.id;
                    dataToUpdate.min = min;
                    dataToUpdate.max = max;
                }
            }
            dataToUpdate.isOpen = !shouldClose;
            if (dataToUpdate.isOpen === false) {
                dataToUpdate.EndTime = record.Date;
            }
        }
        if (Object.keys(dataToUpdate).length === 0) {
            // nothing to update
            return;
        }
        const Case = this.db?.models.case;
        const Op = this.db?.Sequelize.Op;
        const hasCase = await Case.count({
            where: {
                roomId: roomId,
                startDateTime: { [Op.lt]: new Date(endTime) },
                endDateTime: { [Op.gte]: new Date(startTime) }
            }
        });
        if (hasCase) {
            dataToUpdate.inCase = true;
        }
        if (recordInDb) {
            if (this.lastAlertIndex === index && hits && hits.length > 1) {
                for (const hit of hits) {
                    await this.updateRecordInElastic({ id: hit._id, index, doc: dataToUpdate });
                }
            }
            else {
                await this.updateRecordInElastic({ id: recordInDb._id, index, doc: dataToUpdate });
            }
        }
        else {
            const res = await this.dataClient.index({
                method: 'POST',
                index: index,
                body: dataToUpdate
            });
            if (this.lastAlertIndex === index) {
                await this.updateRecordInElastic({ id: res.body._id, index, doc: { id: res.body._id } });
                return { alertId: res.body._id, ...dataToUpdate };
            }
        }
        if (isDuration && recordInDb) {
            return { ...record, ...recordInDb._source, ...dataToUpdate };
        }
        return { ...record, ...dataToUpdate };
    }
    updateRecordInElastic = async ({ id, index, doc }) => {
        let body = {
            doc: { ...doc }
        };
        await this.dataClient.update({
            method: 'POST',
            index: index,
            id: id,
            body: body
        });
    }
    saveAlertRecipient = async ({ data }) => {
        const AlertRecipient = this.db?.models.alertRecipient;
        const logger = this.logger;
        await AlertRecipient.create({ ...data }).then(function (res) {
            if (!res) {
                logger.error('Error in insert new record');
            }
        });
    }
};

export default new Telemetry();
