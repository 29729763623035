import * as yup from 'yup';
import pluralize from 'pluralize';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { api as apiDefs } from './authConfig';
import { dateFormats } from 'dips-models';

dayjs.extend(utc);
const compareValidatorRegex = /^compare:(.+)$/;
const { date, dateTimeFormatSecondAMPM, timeFormat } = dateFormats

class ModelConfig {

    static lookups = {};

    static dateFormat = date;

    static dateTimeFormat = dateTimeFormatSecondAMPM;

    static timeFormat = timeFormat;

    static registerLookup({ name, displayField }) {
        ModelConfig.lookups[name] = displayField;
    }

    static getLookup(name) {
        return ModelConfig.lookups[name];
    }

    createForm({ modelConfig }) {
        const validationConfig = {}, initialValues = {}, formDef = [];
        for (const column of modelConfig.columns) {
            const { field, label, header, type = 'string', required = false, requiredIfNew = false, maxLength, disabled } = column;
            let { defaultValue } = column;
            const formLabel = label || header;
            let config;
            // do not show in form if no header/ caption
            if (!formLabel) {
                continue;
            }
            if (disabled) {
                continue;
            }
            if (column.multiSelect && !Array.isArray(defaultValue)) {
                defaultValue = [];
            }
            if (type === 'boolean') {
                if (typeof defaultValue !== 'boolean') {
                    defaultValue = false;
                }
            }
            if (!column.multiSelect) {
                if (type === 'string' || type === 'password') {
                    if (typeof defaultValue !== 'string') {
                        defaultValue = '';
                    }
                    config = yup.string().label(formLabel);

                    if (required) {
                        config = config.required();
                    }
                    if (requiredIfNew) {
                        config = config.required();
                    }
                    if (column.minLength) {
                        config = config.min(column.minLength);
                    }
                    if (maxLength) {
                        config = config.max(maxLength);
                    }
                    if (column.validate) {
                        switch (column.validate) {
                            case 'isEmail':
                                config = config.email();
                                break;
                            case 'isUrl':
                                config = config.url();
                                break;
                            case 'isNumeric':
                                config = config.number();
                                break;
                            default:
                                compareValidatorRegex.lastIndex = 0;
                                const compareValidator = compareValidatorRegex.exec(column.validate);
                                if (compareValidator) {
                                    const compareFieldName = compareValidator[1];
                                    const compareField = formDef.find(f => f.field === compareFieldName);
                                    config = config.oneOf([yup.ref(compareFieldName)], `${formLabel} must match ${compareField.label || compareField.header}`);
                                    // config = config.when(compareField, {
                                    //     is : (value, schema) => {
                                    //         return value;
                                    //     },
                                    //     then: yup.string().label(formLabel).required().oneOf([yup.ref(compareFieldName)], `${formLabel} must match ${compareField.label}`)
                                    // });

                                    config = config.oneOf([yup.ref(compareFieldName), null], `${formLabel} must match ${compareField.label || compareField.header}`);
                                } else {
                                    throw new Error(`Unknown validation ${column.validate}`);
                                }
                        }
                    }
                    if (column.nullable) {
                        config = config.nullable();
                    }
                }
            }
            if (type === 'number') {
                if (typeof defaultValue !== 'number') {
                    defaultValue = 0;
                }
                config = yup.number().label(formLabel);
                if (required) {
                    config = config.required();
                }
                if (column.decimal !== true) {
                    config = config.integer();
                }
                if (typeof column.minValue === 'number') {
                    config = config.min(column.minValue);
                }
                if (typeof column.maxValue === 'number') {
                    config = config.max(column.maxValue);
                }
                if (column.nullable) {
                    config = config.nullable();
                }
            }
            // TODO: yup model for date
            if (type === 'date') {
                if (Object.prototype.toString.call(defaultValue) === '[object Date]') {
                    defaultValue = new Date();
                }
                config = yup.date().label(formLabel);
                if (required) {
                    config = config.required();
                } else {
                    config = config.nullable();
                }
                if (column.validate) {
                    compareValidatorRegex.lastIndex = 0;
                    const compareValidator = compareValidatorRegex.exec(column.validate);
                    if (compareValidator) {
                        const compareFieldName = compareValidator[1];
                        const compareField = formDef.find(f => f.field === compareFieldName);
                        config = config.min(yup.ref(compareFieldName), `${formLabel} can't be before ${compareField.label || compareField.header}`);
                    } else {
                        throw new Error(`Unknown validation ${column.validate}`);
                    }

                }

            }

            initialValues[field] = defaultValue;
            if (config) {
                validationConfig[field] = config;
            }
            formDef.push(column);
        }

        return {
            validationSchema: validationConfig,
            initialValues,
            formDef
        }
    }

    combineConfig(modelConfig) {
        const { title, api, columns, defaultSort, readOnly } = modelConfig;
        let linkColumn;
        if (!readOnly) {
            ({ linkColumn } = modelConfig);
            if (linkColumn === undefined && defaultSort) {
                linkColumn = defaultSort.split(' ')[0];
            }
        }

        const formConfig = this.createForm({ modelConfig });
        const gridColumns = [];

        for (const column of columns) {
            const headerName = column.headerName || column.header;
            const filter = column.elasticFilter || column.filter;
            if (headerName) {
                let valueGetter;
                if (column.lookup) {
                    valueGetter = (params) => {
                        const originalValue = params.row[params.field];
                        const columnLookup = column.lookup;
                        if (columnLookup) {
                            if (Array.isArray(columnLookup)) {
                                if (!originalValue) {
                                    return "";
                                }
                                if (column.multiSelect) {
                                    let valueArr = originalValue.split(',');
                                    let toReturn = [];
                                    valueArr.forEach(v => {
                                        let item = columnLookup.find(a => a.value === parseInt(v));
                                        if (item) {
                                            toReturn.push(item.label);
                                        }
                                    });
                                    return toReturn.join(",");
                                }
                                else {
                                    let item = columnLookup.find(a => a.value === parseInt(originalValue));
                                    if (item) {
                                        return item.label;
                                    }
                                    return "";
                                }
                            }
                            else {
                                const nested = params.row[columnLookup];
                                if (nested) {
                                    const displayField = ModelConfig.getLookup(column.lookup);
                                    if (displayField) {
                                        return nested.hasOwnProperty(displayField) ? nested[displayField] : originalValue;
                                    }
                                }
                            }
                        }
                        return originalValue;
                    }
                }
                if (column.type === 'date') {
                    valueGetter = (params) => {
                        const originalValue = params.row[params.field];
                        if (originalValue) {
                            if (column.isUtc) {
                                return dayjs.utc(originalValue).format(ModelConfig.dateTimeFormat);
                            }
                            return dayjs(originalValue).format(column.showDateOnly ? ModelConfig.dateFormat : ModelConfig.dateTimeFormat);
                        }
                        return originalValue;
                    }
                }
                if (column.type === 'time') {
                    valueGetter = (params) => {
                        const originalValue = params.row[params.field];
                        if (originalValue) {
                            if (column.isUtc) {
                                return dayjs.utc(originalValue).format(ModelConfig.timeFormat);
                            }
                            return dayjs(originalValue).format(ModelConfig.timeFormat);
                        }
                        return originalValue;
                    }
                }
                gridColumns.push({
                    valueGetter,
                    cellClassName: (readOnly !== true && column.field === linkColumn) ? 'hyperlink' : undefined,
                    headerName,
                    sortable: true,
                    filter: filter,
                    ...column
                });
            }
        }

        const endPoint = api || title;

        return {
            linkColumn,
            listTitle: pluralize(title),
            gridColumns,
            ...modelConfig,
            ...formConfig,
            route: api,
            api: endPoint.startsWith('/') ? endPoint : `${apiDefs.base}/${api || title}`,
        };
    }
}

export { ModelConfig };
export default new ModelConfig();
