import React from 'react';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label, Legend, Line, ComposedChart, ResponsiveContainer } from "recharts";
import { useTheme } from '@mui/styles';

const Graph = (props) => {

    const { data, unit, isDuration, yAxisLabel1 = 'Temperature', useCustomTooltip } = props;
    const labelName = isDuration ? `Open Duration` : `${yAxisLabel1} (in ${unit})`;
    const barDataKey = isDuration ? 'Duration' : 'Range';
    const labelY1 = { value: labelName, angle: -90, position: 'inside', fontSize: 12 };
    const labelY2 = { value: `Duration (in ${unit})`, angle: -90, position: 'outside', fontSize: 12 };

    const { palette } = useTheme();

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { date, Count, Time, Min, Max, Avg } = payload[0].payload;
            return (
                <div style={{
                    backgroundColor: palette.primary.medium,
                    border: `1px solid ${palette.primary.medium}`,
                    color: palette.primary.text.dark,
                    boxShadow: `0px 0px 2px ${palette.primary.text.dark}`,
                    padding: "7px 20px 7px 4px"
                }}>
                    <div style={{ color: palette.primary.text.dark }}>
                        <p>{date}</p>
                        {Count && <p>Count: {Count}</p>}
                        {Time && <p>Duration: {Time}</p>}
                        {Min && <p>Min: {Min}</p>}
                        {Max && <p>Max: {Max}</p>}
                        {Avg && <p>Avg: {Avg}</p>}
                    </div>
                </div>
            );
        }

        return null;
    };


    return (
        <ResponsiveContainer
            width="100%"
            height={320}>
            <ComposedChart
                style={{
                    backgroundColor: palette.primary.light_1,
                    marginBottom: 30,
                    fontSize: "12px"
                }}
                height={300}
                data={data}>
                <XAxis
                    dataKey="date"
                    allowDataOverflow={false}
                    tick={{ fill: palette.primary.text.dark }}
                />
                <YAxis
                    //label={labelY1}
                    allowDataOverflow={false}
                    width={100}
                    yAxisId={"min"}
                    domain={['auto', 'auto']}
                    tick={{ fill: palette.primary.text.dark }}
                >
                    <Label
                        value={labelY1.value}
                        angle={-90}
                        style={{
                            textAnchor: 'middle',
                            fill: palette.primary.text.dark
                        }}
                    />
                </YAxis>
                {isDuration ?
                    <YAxis
                        /// label={labelY2}
                        yAxisId={"max"}
                        orientation="right"
                        domain={['auto', 'auto']}
                        width={150}
                        tick={{ fill: palette.primary.text.dark }}
                    >
                        <Label
                            value={labelY2.value}
                            angle={90}
                            style={{
                                textAnchor: 'middle',
                                fill: palette.primary.text.dark
                            }}
                        />
                    </YAxis> : null}
                <Tooltip content={<CustomTooltip payload={data} />} />
                <Legend />
                <CartesianGrid strokeDasharray="1 1" />
                {isDuration ?
                    <>
                        <Bar yAxisId={"min"} dataKey={"Count"} fill={palette.primary.gridLines} />
                        <Line yAxisId={"max"} dataKey={barDataKey} stroke={palette.primary.gridLines} />
                    </>
                    : <>
                        <Line yAxisId="min" dataKey="Min" stroke={palette.primary.gridLines} dot={false} />
                        <Line yAxisId="min" dataKey="Max" stroke={palette.primary.gridLines} dot={false} />
                        <Line yAxisId="min" type="linear" dataKey="Avg"
                            stroke={palette.primary.gridLines}
                            dot={false} />
                    </>
                }
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default Graph;
