const config = {
	title: 'Temperature History Report',
	name: 'temperatureHistory',
	defaultSort: 'Date desc',
	api: 'telemetry/summary',
	controllerType: 'elastic',
	readOnly: true,
	module: 'temperature',
	columns: [
		{
			field: 'sensorId',
			flex: 1,
			header: 'Sensor',
			lookup: 'sensor',
			required: true,
			hideable: false,
			elasticFilter: false,
			sortable: false,
			filterable: false
		},
		{
			field: 'sensorCode',
			width: 100,
			header: 'Sensor Code',
			type: 'string',
			readOnly: true,
			elasticFilter: false,
			sortable: false,
			filterable: false
		},
		{
			field: 'Date',
			width: 140,
			header: 'Date/time stamp',
			type: 'date',
			isLocal: true,
			elasticFilter: false,
			showDateOnly: true,
			time: false,
		},
		{ field: 'avg', flex: 1, header: 'Reading (C\u00B0)', type: 'number', elasticFilter: false, filterable: false },
		{ field: 'min', flex: 1, header: 'Min (C\u00B0)', type: 'number', elasticFilter: false, filterable: false },
		{ field: 'max', flex: 1, header: 'Max (C\u00B0)', type: 'number', elasticFilter: false, filterable: false }
	]
};

export default config;
