const roleModules = {
	superadmin: {
		dashboard: { add: true, edit: true, delete: true, export: true },
		report: null,
		sensor: { add: true, edit: true, delete: true, export: true },
		sensorType: { add: true, edit: true, delete: true, export: true },
		setup: { add: true, edit: true, delete: true, export: true },
		alertDefinition: { add: true, edit: true, delete: true, export: true },
		alertDefinitionSensor: { add: true, edit: true, delete: true, export: true },
		alertRecipient: { add: false, edit: true, delete: false, export: true },
		case: { add: true, edit: true, delete: true, export: true },
		door: { add: true, edit: true, delete: true, export: true },
		hospital: { add: true, edit: true, delete: true, export: true },
		mqttClient: { add: true, edit: true, delete: true, export: true },
		role: { add: true, edit: true, delete: true, export: true },
		roleModule: { add: true, edit: true, delete: true, export: true },
		room: { add: true, edit: true, delete: true, export: true },
		securityModule: { add: true, edit: true, delete: true, export: true },
		telemetry: { add: true, edit: true, delete: true, export: true },
        telemetrySummary: { add: true, edit: true, delete: true, export: true },
		temperature: { add: true, edit: true, delete: true, export: true },
		user: { add: true, edit: true, delete: true, export: true },
		systemHealth: { add: false, edit: true, delete: true, export: true },
		humidity: { add: false, edit: true, delete: true, export: true },
		alertsHistory: { add: false, edit: true, delete: true, export: true },
		dataFilter: { add: true, edit: true, delete: true, export: false }
	},
	admin: {
		dashboard: { add: true, edit: true, delete: true, export: true },
		report: null,
		sensor: { add: true, edit: true, delete: true, export: true },
		alertDefinition: { add: true, edit: true, delete: true, export: true },
		room: { add: true, edit: true, delete: true, export: true },
		user: { add: true, edit: true, delete: true, export: true },
		setup: { add: true, edit: true, delete: true, export: true },
		hospital: { add: false, edit: false, delete: true, export: true },
		alertRecipient: { add: false, edit: true, delete: false, export: true },
		systemHealth: { add: false, edit: false, delete: false, export: true },
		humidity: { add: false, edit: true, delete: false, export: true },
		alertsHistory: { add: false, edit: false, delete: false, export: true },
		dataFilter: { add: true, edit: true, delete: true, export: false }
	},
	user: {
		dashboard: { add: true, edit: true, delete: true, export: true },
		report: null,
		alertRecipient: { add: false, edit: true, delete: false, export: true },
		humidity: { add: false, edit: false, delete: false, export: true }
	},
	userlimited: {
		dashboard: { add: true, edit: true, delete: true, export: true },
		report: null,
		alertRecipient: { add: false, edit: true, delete: false, export: true }
	}
};

export default roleModules;
